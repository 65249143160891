<template>
  <b-card no-body class="p-3">
    <b-row align-h="center" align-v="center">
      <b-col>
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <b-col :md="form.productType == 'expense' ? 4 : 12">
                <b-form-group :label="$t('apps.masterDataProduct.singular.type')">
                  <b-form-radio-group
                    v-model="form.productType"
                    :options="options.type"
                      :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
              <b-col md="8" v-if="form.productType == 'expense'">
                <b-alert
                  show
                  dismissible
                  fade
                  class="mb-0"
                  variant="primary"
                >
                  <div class="alert-body">
                    <span>Expenses type are for additional charge like Freight Charge when filling Purchase Order </span>
                  </div>
                </b-alert>
              </b-col>
              <b-col md="2">
                <b-form-group
                  :label="$t('apps.masterDataProduct.singular.code')"
                  label-for="code">
                  <b-form-input
                    id="code"
                    size="sm"
                    v-model="form.code"
                    :placeholder="$t('globalSingular.auto')"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-group
                  :label="$t('apps.masterDataProduct.singular.name')"
                  label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      size="sm"
                      v-model="form.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="
                        !actions.isPreview
                          ? $t('apps.masterDataProduct.placeholder.name')
                          : null
                      "
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  :label="$t('apps.masterDataProduct.singular.unitofmeasure')"
                  label-for="unitofmeasure">
                  <validation-provider
                    #default="{ errors }"
                    name="unitofmeasure"
                    rules="required"
                  >
                    <v-select
                      id="product-unitofmeasure"
                      v-model="form.uomId"
                      :options="LOV.uom"
                      :reduce="field => field.id"
                      label="uom"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataProduct.placeholder.unit')  : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  :label="$t('apps.masterDataProduct.singular.brand')"
                  label-for="brand">
                  <validation-provider
                    #default="{ errors }"
                    name="brand"
                  >
                    <v-select
                      id="product-brand"
                      v-model="form.brandId"
                      :options="LOV.brand"
                      :reduce="field => field.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataProduct.placeholder.brand')  : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--category -->
              <b-col md="3">
                <b-form-group
                  :label="$t('apps.masterDataProduct.singular.category')"
                  label-for="category">
                  <validation-provider
                    #default="{ errors }"
                    name="category"
                    rules="required"
                  >
                    <v-select
                      id="product-category"
                      v-model="form.categoryId"
                      :options="LOV.category"
                      :reduce="field => field.id"
                      @option:selected="categoryChange"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataProduct.placeholder.category')  : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- sub category  -->
              <b-col md="3">
                <b-form-group
                  :label="$t('apps.masterDataProduct.singular.subCategory')"
                  label-for="subCategory">
                  <validation-provider
                    #default="{ errors }"
                    name="subCategory"
                  >
                    <v-select
                      id="product-category"
                      v-model="form.subCategoryId"
                      :options="LOV.subCategory"
                      :reduce="field => field.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      :placeholder="!actions.isPreview ? $t('apps.masterDataProduct.placeholder.category')  : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group :label="$t('apps.masterDataProduct.singular.isInventory')"> 
                  <b-form-checkbox
                      v-model="form.inventoryType"
                      :options="options.inventory"
                      :value="true"
                      class="mr-0 custom-control-inline"
                      :disabled="actions.isPreview"
                    />
                    <span class="font-weight-bold">{{$t('apps.masterDataProduct.singular.isInventory')}}</span>
                  </b-form-group> 
              </b-col>
            </b-row>

            <b-row>
            
            <b-col md="12">
              <hr class="my-2">
            </b-col>
              
            <b-col md="6">
              <b-form-group>
                <b-form-checkbox
                  v-model="form.isBuying"
                  :options="options.isBuy"
                  :value="true"
                  class="mr-0 custom-control-inline"
                  :disabled="actions.isPreview"
                />
                <span class="font-weight-bold">{{$t('apps.masterDataProduct.singular.isBuying')}}</span>
              </b-form-group>
              <b-form-group
                :label="$t('apps.masterDataProduct.singular.buyingAmount')"
                label-for="buyingAmount">
                <validation-provider
                  #default="{ errors }"
                  name="buyingamount"
                >
                  <cleave
                    id="buyingAmount"
                    v-model.number="form.buyingAmount"
                    class="form-control form-control-sm"
                    :raw="true"
                    :options="options.number"
                    placeholder="Sugestion Price, ex : 500.000"
                    :disabled="actions.isPreview"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row>
                <b-col  md="6">
                  <b-form-group
                    :label="$t('apps.masterDataProduct.singular.buyingAccount')"
                    label-for="buyingAccount">
                    <validation-provider
                      #default="{ errors }"
                      name="buyingaccount"
                    >
                      <v-select
                        id="buyingAccount"
                        v-model="form.buyingAccountId"
                        :options="LOV.account"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="select-size-sm"
                        :placeholder="!actions.isPreview ? $t('apps.masterDataProduct.singular.buyingAccount')  : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('apps.masterDataProduct.singular.buyingReturnAccount')"
                    label-for="buyingReturnAccount">
                    <validation-provider
                      #default="{ errors }"
                      name="buyingreturnaccount"
                    >
                      <v-select
                        id="buyingReturnAccount"
                        v-model="form.buyingReturAccountId"
                        :options="LOV.account"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="select-size-sm"
                        :placeholder="!actions.isPreview ? $t('apps.masterDataProduct.singular.buyingReturnAccount')  : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>


            </b-col>
            <b-col md="6">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="form.isSell"
                      :options="options.isSell"
                      :value="true"
                      class="mr-0 custom-control-inline"
                      :disabled="actions.isPreview"
                    />
                    <span class="font-weight-bold">{{$t('apps.masterDataProduct.singular.isSelling')}}</span>
                  </b-form-group>
                  <b-form-group
                    :label="$t('apps.masterDataProduct.singular.sellingAmount')"
                    label-for="sellingAmount">
                    <validation-provider
                      #default="{ errors }"
                      name="sellinggamount"
                    >
                      <cleave
                        id="sellingAmount"
                        v-model="form.sellAmount"
                        class="form-control form-control-sm"
                        :raw="true"
                        :options="options.number"
                        placeholder="Sugestion Sell Price, ex : 100,000"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              <b-row>
                <b-col  md="6">
                  <b-form-group
                    :label="$t('apps.masterDataProduct.singular.sellingAccount')"
                    label-for="sellingAccount">
                    <validation-provider
                      #default="{ errors }"
                      name="sellingaccount"
                    >
                      <v-select
                        id="sellingAccount"
                        v-model="form.sellAccountId"
                        :options="LOV.account"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="select-size-sm"
                        :placeholder="!actions.isPreview ? $t('apps.masterDataProduct.singular.buyingAccount')  : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('apps.masterDataProduct.singular.sellingReturnAccount')"
                    label-for="sellingReturnAccount">
                    <validation-provider
                      #default="{ errors }"
                      name="sellingreturnaccount"
                    >
                      <v-select
                        id="sellingReturnAccount"
                        v-model="form.sellReturAccountId"
                        :options="LOV.account"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                        class="select-size-sm"
                        :placeholder="!actions.isPreview ? $t('apps.masterDataProduct.singular.buyingReturnAccount')  : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>


            </b-col>
            
            </b-row>
            
            <div
              class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between"
            >
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="
                  actions.isPreview ? 'outline-secondary' : 'outline-primary'
                "
                :to="{ name: 'apps-master-data-product-list' }"
              >
                <feather-icon icon="ArrowLeftIcon" />
                {{ $t("globalActions.backToList") }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon icon="Trash2Icon" />
                {{ $t("globalActions.delete") }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon icon="SaveIcon" />
                {{
                  actions.isEditMode
                    ? $t("globalActions.update")
                    : $t("globalActions.create")
                }}
              </b-button>

              <b-button v-else variant="outline-primary" @click="changeToEdit">
                <feather-icon icon="EditIcon" />
                {{ $t("globalActions.changeToEdit") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
// import i18n from '@/libs/i18n'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormCheckbox,
  // BFormCheckboxGroup,
  BFormInput,
  BForm,
  BAlert,
  // BFormTextarea,
  BButton
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckbox,
    // BFormCheckboxGroup,
    BForm,
    BAlert,
    // BFormTextarea,
    BButton,
    Cleave,
    // BFormText,
    // BFormDatalist,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({
      url: 'master/product',
      localeContextPath: 'apps.masterDataProduct.singular.product',
      redirectPathName: 'apps-master-data-product'
    })

    const LOV = ref({
      uom:[],
      brand:[],
      category:[],
      subCategory:[],
      account:[]
    })

    const form = ref({
      code                   : null,
      name                   : null,
      categoryId             : null,
      subCategoryId          : null,
      brandId                : null,
      uomId                  : null,
      productType            : 'goods',
      inventoryType          : 'nonInventory',
      isBuying               : false,
      buyingAmount           : null,
      buyingAccountId        : null,
      buyingTaxId            : null,
      buyingReturAccountId   : null,
      isSell                 : false,
      sellAmount             : null,
      sellAccountId          : null,
      sellTaxId              : null,
      sellReturAccountId     : null
    })

    const subCat = ref({})


    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      form.value.code                  = data.code
      form.value.name                  = data.name
      form.value.categoryId            = data.category_id
      form.value.brandId               = data.brand_id
      form.value.uomId                 = data.uom_id
      form.value.productType           = data.product_type
      form.value.inventoryType         = data.inventory_type
      form.value.isBuying              = data.is_buying
      form.value.buyingAmount          = data.buying_amount
      form.value.buyingAccountId       = data.buying_account_id
      form.value.buyingTaxId           = data.buying_tax_id
      form.value.buyingReturAccountId  = data.buying_retur_account_id
      form.value.isSell                = data.is_sell
      form.value.sellAmount            = data.sell_amount
      form.value.sellAccountId         = data.sell_account_id
      form.value.sellTaxId             = data.sell_tax_id
      form.value.sellReturAccountId    = data.sell_retur_account_id
      getSubCategoriesEdit(data.category_id)
      subCat.value                    = data.sub_category_id

    }

    const getUOM = async () => {
      LOV.value.uom = await get({ url: 'value/uom' })
    }

    const getBrand = async () => {
      LOV.value.brand = await get({ url: 'master/product-brand' })
    }

    const getCategory = async () => {
      LOV.value.category = await get({ url: 'master/product-category' })
    }
    const getSubCategories = async categoryId => {
      LOV.value.subCategory = await get({ url: `value/product-sub-category?category_id=${categoryId}` })
    }

    const getSubCategoriesEdit = async categoryId => {
      LOV.value.subCategory = await get({ url: `value/product-sub-category?category_id=${categoryId}` })
      form.value.subCategoryId    = subCat.value

    }

    const categoryChange = option => getSubCategories(option.id)

    const getAccount = async () => {
      LOV.value.account = await get({ url: 'master/accounts'})
    }

    onMounted(() => {
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      getUOM()
      getBrand()
      getCategory()
      getAccount()
    })

    return {
      required,
      form,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      categoryChange,
      store,
      LOV,
      options: {
        type: [
          { text: 'Goods', value: 'goods'},
          { text: 'Service', value: 'service' },
          { text: 'Expenses', value: 'expense' },
          { text: 'Taxes', value: 'tax' }
        ],
        inventory : [{ item: 'inventory', name: 'Inventory'}],
        isBuy : [{ item: true, name: 'Is Buying'}],
        isSell : [{ item: true, name: 'Is Sell'}],
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      }
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    handleSubmit () {
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
